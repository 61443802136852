exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-clients-assisted-living-tsx": () => import("./../../../src/pages/clients/assisted-living.tsx" /* webpackChunkName: "component---src-pages-clients-assisted-living-tsx" */),
  "component---src-pages-confirmed-call-scheduled-tsx": () => import("./../../../src/pages/confirmed/call-scheduled.tsx" /* webpackChunkName: "component---src-pages-confirmed-call-scheduled-tsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */)
}

